<template>
  <div class="yfmbrout_heard">
    <!-- 区域选择dialog -->
    <el-dialog title="区域选择" :visible.sync="cityDialog" width="672px" :before-close="handleClose">
      <!-- <el-transfer filterable :titles="['地区选择', '已选择20区域']" :filter-method="filterMethod" filter-placeholder="请输入城市拼音"
      v-model="value" :data="data" :render-content="renderFunc"></el-transfer>-->
      <TreeTransfer ref="transferRef" :title="['区域选择', `已选择 ${0} 个区域`]" :from_data="transferInfo.fromData"
        :to_data="transferInfo.toData" :defaultProps="{ label: 'name', children: 'list' }" pid="parent_id" height="540px"
        :filter="false">
        <template v-slot:from>
          <el-input v-model="transferInfo.name" placeholder="搜索"></el-input>
        </template>

        <template v-slot:to>
          <el-input v-model="transferInfo.name" placeholder="搜索"></el-input>
        </template>
      </TreeTransfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleTransferCancel">取 消</el-button>
        <el-button type="primary" @click="handleTransferSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- back head -->
    <div @click="fhubtn" class="textmessage_top">
      <span>
        <i class="el-icon-arrow-left"></i> 返回&nbsp;/
      </span>
      <span>新增运费模版</span>
    </div>
    <!-- content -->
    <div class="textmessage_bottom">
      <div class="textmessage_bottom_text">
        <p>新增运费模版</p>
        <span>提供专业、快速、低成本的短信平台发送和短信接口，满足各类企业需求</span>
      </div>
      <div class="textmessage_bottom_form">
        <el-form :rules="rules" ref="form" :model="form" label-width="93px">
          <el-form-item label="模板名称：" prop="rule_name">
            <el-input placeholder="请输入模板名称" v-model="form.rule_name"></el-input>
          </el-form-item>
          <div class="textmessage_bottom_formfy">
            <el-form-item label="计费方式：" prop="name">
              <el-radio-group v-model="form.billing_type">
                <el-radio :label="1">按件数</el-radio>
                <el-radio :label="0">按重量</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="textmessage_bottom_formfy_01">
            <el-form-item label="配送区域：" prop="name">
              <el-table class="tabile01" :data="form.able_delivery_areas" style="width: 100%">
                <!-- show-overflow-tooltip -->
                <el-table-column label="配送区域" width="680">
                  <template slot-scope="scope">
                    <span>{{ addressResourceMap2String(scope.row.province_list) }}</span>
                    <!-- <span>{{scope.row}}</span> -->
                    <span style="color:var(--fontColor);margin-left:12px; cursor: pointer"
                      @click="handleEnabledModyfy(scope)">编辑</span>
                    <span style="var(--fontColor);margin-left:16px; cursor: pointer"
                      @click="handleEnabledDelete(scope)">删除</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="frist" label="首件(个)" width="150">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.frist" :min="0" :controls="false"
                      placeholder="请输入内容"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="frist_price" label="运费(元)" width="150">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.frist_price" :min="0" :controls="false"
                      placeholder="请输入内容"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="second" label="续件(个)" width="150">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.second" :min="0" :controls="false"
                      placeholder="请输入内容"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="second_price" :min="0" :controls="false" label="续费(元)" width="150">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.second_price" :min="0" :controls="false"
                      placeholder="请输入内容"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="starting_delivery_price" width="150" label="起送金额(元)">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.starting_delivery_price" :min="0" :controls="false"
                      placeholder="请输入内容"></el-input-number>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </div>
          <div class="btnbtn">
            <!-- :class="{ 'dtbutton': btnr == 1 }" -->
            <el-button @click="btnbclick" icon="el-icon-circle-plus-outline">
              添加可配送区域和运费
            </el-button>
            <!-- v-if="btnr == 0" :class="{ 'dtbutton': btnr == 0 }" -->
          </div>
          <!--  v-if="btnr == 1" -->
          <div class="aaabpsqy">
            <span>不配送区域：</span>
            <div class="bpsqy">
              <span class="city-name">
                {{ this.form.disable_delivery_areas.length > 0 ?
                  addressResourceMap2String(this.form.disable_delivery_areas.map(item => item['province_list']).flat(1)) :
                  "暂无不配送区域" }}
              </span>
              <span @click="handleEdit" v-show="this.form.disable_delivery_areas.length > 0">编辑</span>
              <span @click="handleEmpty" v-show="this.form.disable_delivery_areas.length > 0">清空</span>
            </div>
          </div>
          <div class="btnbtn">
            <el-button @click="btnbclickt" icon="el-icon-circle-plus-outline"
              v-show="!$route.query.id || this.form.disable_delivery_areas.length == 0">
              添加不配送区域
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <!-- footer -->
    <diy-footer-button
      v-if="id ? (form.is_default ? hasPerm(['delivery.rules.set_default']) : hasPerm(['delivery.rules.update'])) : hasPerm(['delivery.rules.store'])">
      <el-button type="primary" @click="handleSave">保存</el-button>
    </diy-footer-button>
  </div>
</template>

<script>
import TreeTransfer from "el-tree-transfer"; // 引入
import { Message } from "element-ui";
export default {
  components: {
    TreeTransfer
  },
  data() {
    return {
      transferInfo: {
        fromData: [],
        toData: [],
        type: "" //辨别操作类型，enabled:选择配送 disabled:不配送
      },
      form: {
        rule_name: "", //模版名称
        billing_type: 1, //计费方式 0 重量 1 数量
        able_delivery_areas: [], //允许配送地址
        disable_delivery_areas: [] //不允许配送地址
      },
      cityDialog: false,
      isModifyAdd: false, //是否是编辑时添加
      modifyAddIndex: -1,
      modifyConcatResource: [],

      rules: {
        rule_name: [
          { required: true, message: "请输入模板名称", trigger: "blur" }
        ]
      },
      id: this.$route.query.id
    };
  },
  created() {
    this.getAddressListInfo();
    if (this.$route.query.id) {
      this.getRulesInfoOfId();
    }
  },
  mounted() { },
  methods: {
    // 将省市区数据转为地址拼接
    addressResourceMap2String(addArray) {
      return addArray.reduce((pre, item, index) => {
        pre += `${item.name} ${(item.list &&
          item.list.length > 0 &&
          "(" + this.addressResourceMap2String(item.list) + ")") ||
          ""} `;
        return pre;
      }, "");
    },

    // 不可配送编辑
    handleEdit() {
      this.transferInfo.type = "disabled";
      if (this.$route.query.id) {
        for (const value of this.form.disable_delivery_areas) {
          this.$set(
            this.transferInfo,
            "toData",
            this.transferInfo.toData.concat(value["province_list"])
          );
        }
      }
      this.cityDialog = true;
    },
    // 不可配送清空
    handleEmpty() {
      let arr = [];
      this.$set(this.form, "disable_delivery_areas", []);
      for (const value of this.form.able_delivery_areas) {
        arr = [...arr, ...value["province_list"]];
      }
      for (const value of this.form.disable_delivery_areas) {
        arr = [...arr, ...value["province_list"]];
      }
      // 通过 modifyConcatResource 先动态生成 fromData
      const ids = this.getRightDataIds(arr, []);
      if (ids.length) {
        this.$set(
          this.transferInfo,
          "fromData",
          this.filterLeftData(this.transferInfo.fromData, ids, [])
        );
      }
    },
    // 清空
    empty() {
      this.btnr == 0;
    },
    fhubtn() {
      this.$router.push("/setup/ordination");
    },

    // 截取数据到市
    subResource2City(resource) {
      return resource.map(item => {
        if (item.hasOwnProperty("list") && item.list.length > 0) {
          item.list = [
            ...item.list.map(ite => {
              if (item.hasOwnProperty("list")) {
                // delete ite['list'];
                ite["list"] = [];
                ite["isLast"] = true;
                return ite;
              } else {
                return ite;
              }
            })
          ];
          return item;
        }
      });
    },
    // 地址映射
    mapAddress(arr) {
      const result = arr.map(item => {
        item.parent_id = 0;
        return item;
      });
      return result;
    },
    // 获取省市区数据
    getAddressListInfo() {
      this.$get(this.$apis.cityList).then(res => {
        if (res.code != 200) {
          return Message.error(res.message);
        }
        this.$set(
          this.transferInfo,
          "fromData",
          this.mapAddress(this.subResource2City(res.data))
        );
      });
    },
    // 配送
    btnbclick() {
      this.transferInfo.type = "enabled";
      if (this.$route.query.id) {
        this.isModifyAdd = true;
      }
      this.cityDialog = true;
    },
    // 添加不可配送
    btnbclickt() {
      this.transferInfo.type = "disabled";
      if (this.$route.query.id) {
        // this.getRulesInfoOfId();
        // for (const value of this.form.disable_delivery_areas) {
        //   this.$set(this.transferInfo, 'toData', this.transferInfo.toData.concat(value['province_list']))
        // }
      }
      this.cityDialog = true;
    },
    // 取消
    handleTransferCancel() {
      this.$refs["transferRef"]["clearChecked"]();
      this.$set(this.transferInfo, "toData", []);
      this.cityDialog = false;
      this.modifyAddIndex = -1;
    },
    handleClose() {
      this.handleTransferCancel();
    },
    // 提交
    handleTransferSubmit() {
      switch (this.transferInfo.type) {
        case "enabled":
          const enabledObj = {
            starting_delivery_price: "",
            frist: "",
            frist_price: "",
            second: "",
            second_price: "",
            province_list: []
          };
          // 判断是否选择了地址
          if (this.transferInfo.toData.length == 0) {
            return Message.warning("请选择配送地址!");
          }
          enabledObj["province_list"] = this.transferInfo.toData;
          if (this.$route.query.id && this.isModifyAdd) {
            this.form.able_delivery_areas.push(enabledObj);
          } else if (this.$route.query.id && this.modifyAddIndex >= 0) {
            this.$set(
              this.form.able_delivery_areas[this.modifyAddIndex],
              "province_list",
              this.transferInfo.toData
            );
          } else {
            this.form.able_delivery_areas.push(enabledObj);
          }
          break;
        case "disabled":
          const disabledObj = { province_list: [] };
          disabledObj["province_list"] = this.transferInfo.toData;
          this.$route.query.id
            ? (this.form.disable_delivery_areas = Array.of(disabledObj))
            : this.form.disable_delivery_areas.push(disabledObj);
          break;
      }
      this.handleTransferCancel();
    },
    // 保存
    handleSave() {
      this.$refs["form"]["validate"](valid => {
        if (valid) {
          this[(() => (this.$route.query.id ? "$put" : "$post"))()](
            `${this.$route.query.id
              ? this.$apis.deliveryRuleApi + "/" + this.$route.query.id
              : this.$apis.deliveryRuleApi
            }`,
            JSON.parse(JSON.stringify(this.form))
          ).then(res => {
            if (res.code == 200) {
              Message.success({
                message: `${this.$route.query.id ? "编辑" : "新建"
                  }配送规则成功!`,
                onClose: () => {
                  this.$router.push("/setup/ordination");
                }
              });
              this.handleTransferCancel();
            } else {
              Message.error(res.message);
            }
          });
        }
      });
    },
    // 通过id获取详情
    getRulesInfoOfId() {
      this.$get(`${this.$apis.deliveryRuleApi}/${this.$route.query.id}`).then(
        res => {
          if (res.code != 200) {
            return Message.error(res.message);
          }
          this.$nextTick(() => {
            this.$set(this, "form", res.data);
            // 点击编辑后，需要将可配送数据与不可配送数据进行结合，做到互斥效果
            // 1.获取可配送的数据
            for (const value of res.data.able_delivery_areas) {
              this.$set(
                this,
                "modifyConcatResource",
                this.modifyConcatResource.concat(value["province_list"])
              );
            }
            for (const value of res.data.disable_delivery_areas) {
              this.$set(
                this,
                "modifyConcatResource",
                this.modifyConcatResource.concat(value["province_list"])
              );
            }
            // 通过 modifyConcatResource 先动态生成 fromData
            const ids = this.getRightDataIds(this.modifyConcatResource, []);
            if (ids.length) {
              this.$set(
                this.transferInfo,
                "fromData",
                this.filterLeftData(this.transferInfo.fromData, ids, [])
              );
            }
          });
        }
      );
    },

    // 获取右侧数据 id
    getRightDataIds(list, rightIds) {
      for (let rightItem of list) {
        rightIds.push(rightItem.id);
        if (rightItem.list && rightItem.list.length) {
          this.getRightDataIds(rightItem.list, rightIds);
        }
      }
      return rightIds;
    },
    // 处理过滤数据
    getFilterLeftData() {
      let rightIds = this.getRightDataIds(this.transferInfo.toData, []);
      this.transferInfo.fromData = this.filterLeftData(
        this.transferInfo.fromData,
        rightIds,
        []
      );
    },

    filterLeftData(list, rightIds, newList) {
      for (let leftItem of list) {
        if (rightIds.includes(leftItem.id)) {
          if (leftItem.list && leftItem.list.length) {
            let insetBool = false;
            for (let child of leftItem.list) {
              if (!rightIds.includes(child.id)) insetBool = true;
            }
            if (insetBool) {
              let jsonItem = JSON.parse(JSON.stringify(leftItem));
              jsonItem.list = [];
              newList.push(jsonItem);
              this.filterLeftData(leftItem.list, rightIds, jsonItem.list);
            }
          }
        } else {
          let jsonItem = JSON.parse(JSON.stringify(leftItem));
          // jsonItem.list = [];
          newList.push(jsonItem);
        }
      }
      return newList;
    },

    // 配送编辑
    handleEnabledModyfy({ row, $index }) {
      this.transferInfo.type = "enabled";
      this.cityDialog = true;
      this.modifyAddIndex = $index;
      this.$set(this.transferInfo, "toData", row["province_list"]);
    },
    // 可配送删除
    handleEnabledDelete({ row, $index }) {
      let arr = [];
      this.form.able_delivery_areas.splice($index, 1);
      for (const value of this.form.able_delivery_areas) {
        arr = [...arr, ...value["province_list"]];
      }
      for (const value of this.form.disable_delivery_areas) {
        arr = [...arr, ...value["province_list"]];
      }
      // 通过 modifyConcatResource 先动态生成 fromData
      const ids = this.getRightDataIds(arr, []);
      if (ids.length) {
        this.$set(
          this.transferInfo,
          "fromData",
          this.filterLeftData(this.transferInfo.fromData, ids, [])
        );
      }
    }
  }
};
</script>

<style lang="less" scoped>
.yfmbrout_heard {
  border-bottom: none;

  & /deep/.el-dialog__header {
    border-bottom: none;
  }

  & /deep/.el-dialog__footer {
    border: none;
  }

  & /deep/.el-dialog__body {
    padding: 0 32px !important;
    box-sizing: border-box;
  }

  // transfer css
  & /deep/.transfer-left,
  /deep/.transfer-right {
    width: 280px;
    // height: 450px;
    background: #ffffff;
    border-radius: 3px;
    // border: 1px solid #DDDFE6;
    border: none;

    & .transfer-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: flex;
      align-items: center;
      background-color: #fff;
      border: none;

      & .el-checkbox {
        display: none;
      }
    }

    & .transfer-main {
      width: 100%;
      border: 1px solid #ebeef5;

      & .el-tree {
        margin-top: 10px;
      }

      & .el-tree-node {
        min-height: 38px;

        & .el-tree-node__content {
          width: 100%;
          min-height: 38px !important;
        }
      }

      & .el-tree-node__expand-icon {
        font-size: 14px;
        // background: #FFFFFF;
        color: rgba(0, 0, 0, 0.6);
      }

      & .custom-tree-node {
        // width: 42px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
      }

      & .el-checkbox {
        position: absolute;
        right: 0;
      }
    }
  }

  & /deep/.transfer-center {
    width: 48px;
    left: 46%;

    & button {
      width: 24px;
      height: 24px;
      background: #eeeeee !important;
      border: 1px solid #dcdcdc !important;
      border-radius: 4px;
      padding: 0 !important;

      & i::before {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }

  .textmessage_top {
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;

    span:first-child {
      margin-left: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }

    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }
  }

  .textmessage_bottom {
    padding: 32px;
    border-radius: 4px;
    background: #fff;
    box-sizing: border-box;

    .textmessage_bottom_text {
      p {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
        color: #000;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .textmessage_bottom_form {
      & /deep/.el-form {
        clear: both;

        & .el-form-item {
          margin-bottom: 32px;

          & .el-form-item__label {
            padding: 0 8px 0 0;
          }
        }
      }

      .btnbtn {
        margin-top: 20px;
        margin-left: 100px;

        .dtbutton {
          border: none;
        }

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: var(--primary);
        }
      }

      // border: 1px solid;
      padding: 40px 0px 0 0px;

      .textmessage_bottom_formfy {
        // margin: 0px 0px 32px 1px;

        span:last-child {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          //   margin-left: 1px;
        }

        span {
          margin-left: 6px;
          font-size: 14px;
          color: #606266;
        }

        .el-radio:last-child {
          // margin-left: 9px;
          color: #333333;
        }

        .el-radio:first-of-type {
          margin-left: 9px;
          color: #333333;
        }
      }

      .textmessage_bottom_formfy_01 {
        /deep/.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
          background: #535353;
        }

        /deep/.el-table--scrollable-x .el-table__body-wrapper {
          background: transparent;
        }

        // display: flex;
        // margin-top: 32px;

        /deep/ .el-table th.el-table__cell {
          background-color: #f9f9fc;
          color: #333;
        }

        .el-input-number {
          width: 120px;
          // height: 32px;
        }

        /deep/ .el-input {
          width: 120px;
        }

        /deep/.el-input__inner {
          -webkit-appearance: none;
          background-color: #fff;
          background-image: none;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          box-sizing: border-box;
          color: #606266;
          display: inline-block;
          height: 40px;
          line-height: 40px;
          outline: 0;
          padding: 0 15px;
          transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          width: 120px;
          text-align: center;
        }

        // margin-left: 24px;
        span {
          margin-left: 6px;
          font-size: 14px;
          color: #606266;
        }

        .tabile01 {
          // margin: -13px 0 0 10px;
        }
      }

      .aaabpsqy {
        margin-top: 20px;
        margin-left: 15px;
        display: flex;
        align-items: center;

        & span:nth-of-type(1) {
          flex-shrink: 0;
        }

        .bpsqy {
          border: 1px solid #dddfe6;
          width: 100%;
          min-height: 38px;
          border-radius: 3px;

          span {
            line-height: 38px;
            margin-left: 16px;
          }

          span:nth-last-child(2) {
            color: var(--fontColor);
            cursor: pointer;
          }

          span:nth-last-child(1) {
            color: var(--fontColor);
            cursor: pointer;
          }

          & .city-name {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333 !important;
          }
        }

        span {
          width: 90px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .addtion_foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 160px;
    box-sizing: border-box;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 6;
    background-color: #fff;

    .addtion_foot-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px !important;
    }
  }
}
</style>